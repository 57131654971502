import React from "react";
import { FaBookOpen, FaPen, FaChevronDown } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/Dialog"

const HeroBanner = () => {
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight - 50,
      behavior: "smooth",
    });
  };

  return (
    <div className="relative">
      <div className="bg-white text-black px-20 py-4 shadow-sm flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0 lg:space-x-10 h-[100vh]">
        <div className="lg:w-1/2 flex flex-col items-start space-y-4">
          <h1 className="text-5xl">Answer life's hardest questions</h1>
          <p className="text-lg">Embark on an intentional journey of self-discovery</p>
          <div className="flex space-x-4">
            <a
              href="/articles"
              className="bg-transparent text-black hover:bg-gray-200 hover:text-black py-3 px-6 border border-black rounded transition duration-200 shadow-lg flex items-center"
            >
              Start Reading
              <FaBookOpen className="ml-2" />
            </a>
            <a
              href="/intentional-journal"
              className="bg-gray-900 text-white hover:bg-gray-600 py-3 px-6 border border-black rounded transition duration-200 shadow-lg flex items-center"
            >
              Start Writing
              <FaPen className="ml-2" />
            </a>
          </div>
          <Dialog>
            <DialogTrigger className="text-gray-600 hover:text-gray-800 text-lg py-0 transition duration-200 flex items-center underline cursor-pointer">
              <IoIosInformationCircleOutline className="mr-2" />What is Intentional Questions?
            </DialogTrigger>
            <DialogContent className="bg-white p-6">
              <DialogHeader>
                <DialogTitle className="mb-2">What is Intentional Questions?</DialogTitle>
                <DialogDescription>
                  Intentional Questions is a platform for self-discovery and personal growth. We provide a space for you to reflect on life's hardest questions and document your thoughts.
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>

        </div>
        <div className="lg:w-1/2 p-10">
          <img
            src="/icon.png"
            alt="A descriptive alternative text"
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
      </div>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <FaChevronDown
          className="text-4xl cursor-pointer animate-bounce text-gray-400"
          onClick={scrollToContent}
        />
      </div>
    </div>
  );
};

export default HeroBanner;

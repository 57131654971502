import React from "react";
import HeroBanner from "./HeroBanner";
import DailyThoughts from "./DailyThoughts";
import Latest from "./Latest";
import HomeFooter from "./HomeFooter";

function Home() {
  return (
    <div>
      <HeroBanner />
      <div className="mx-auto px-2 sm:px-24">
        <DailyThoughts />
        <Latest />
      </div>
      <HomeFooter />
    </div>
  );
}

export default Home;

import React from "react";
import { Link } from "react-router-dom";

const ArticlePreview = ({ title, excerpt, imageUrl, articleID }) => {
  return (
    <Link
      to={`/articles/${articleID}`}
      className="block border  overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in-out bg-white"
    >
      <div className="w-full h-48 overflow-hidden">
        <img
          src={imageUrl}
          alt="Article"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-4">
        <h2 className="text-xl font-medium">{title}</h2>
        <p className="mt-2 font-thin">{excerpt}</p>
      </div>
    </Link>
  );
};

export default ArticlePreview;

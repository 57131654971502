import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import Return from "../../components/Return";
import toast from "react-hot-toast";

const Archive = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState({
    id: null,
    text: "",
    quotee: "",
  });
  const [modalState, setModalState] = useState(null); // null, "new", or entry object
  const jwt = localStorage.getItem("jwt");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/user-quotes`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const data = await response.json();
        setEntries(data.data);
      } catch (error) {
        console.error("Failed to fetch entries", error);
      }
    };

    if (jwt) {
      fetchEntries();
    }
  }, [jwt]);

  const handleTextChange = (e) =>
    setCurrentEntry({ ...currentEntry, text: e.target.value });
  const handleQuoteeChange = (e) =>
    setCurrentEntry({ ...currentEntry, quotee: e.target.value });

  const openModal = (entry = null) => {
    if (entry) {
      setCurrentEntry({
        id: entry.id,
        text: entry.attributes.text,
        quotee: entry.attributes.quotee,
      });
      setModalState(entry);
    } else {
      setCurrentEntry({ id: null, text: "", quotee: "" });
      setModalState("new");
    }
  };
  const closeModal = () => {
    setModalState(null);
    setCurrentEntry({ id: null, text: "", quotee: "" });
  };
  const saveEntry = async () => {
    const url = currentEntry.id
      ? `${apiUrl}/api/user-quotes/${currentEntry.id}`
      : `${apiUrl}/api/user-quotes`;

    const method = currentEntry.id ? "PUT" : "POST";
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            text: currentEntry.text,
            quotee: currentEntry.quotee,
          },
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (currentEntry.id) {
          setEntries(
            entries.map((entry) =>
              entry.id === currentEntry.id ? data.data : entry
            )
          );
        } else {
          setEntries([...entries, data.data]);
        }
        setCurrentEntry({ text: "", quotee: "" });
        setModalState(false);
        toast.success("Quote Saved");
      } else {
        toast.error("Quote Failed to Save");
      }
    } catch (error) {
      console.error("Failed to save entry", error);
      toast.error("Failed to save entry");
    }
  };

  const deleteEntry = async (entryId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this quote?"
    );

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}/api/user-quotes/${entryId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (response.ok) {
          setEntries(entries.filter((entry) => entry.id !== entryId));
          closeModal();
        } else {
          console.error("Failed to delete entry");
        }
      } catch (error) {
        console.error("Failed to delete entry", error);
      }
    }
  };

  return (
    <div className="px-12 mt-14">
      <Return />
      <div className="flex justify-between items-center mb-10 mt-32 px-2">
        <h2 className="text-2xl">Quote Archive</h2>
        <button
          onClick={() => openModal()}
          className="px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-300"
        >
          <i className="fas fa-quote-right"></i> {/* Notepad icon */}
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {entries.map((entry, index) => (
          <div
            key={index}
            onClick={() => openModal(entry)}
            className="block border rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in-out bg-white cursor-pointer"
          >
            <div className="p-4 flex flex-col h-full justify-between">
              <blockquote className="text-xl font-medium">
                “{entry.attributes.text}”
              </blockquote>
              <cite className="text-right text-sm mt-4">
                {"- " + entry.attributes.quotee}
              </cite>
            </div>
          </div>
        ))}
      </div>
      {modalState && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 flex justify-center items-center">
          <div className="relative bg-white p-8 rounded-lg w-11/12 sm:w-3/4 md:w-1/2">
            {/* Close button */}
            <button
              onClick={() => setModalState(false)}
              className="absolute top-0 right-0 mt-2 mr-2 p-2 bg-transparent text-black hover:text-gray-700 font-semibold"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <h2 className="text-2xl mb-2">
              {modalState === "new" ? "Create New Quote" : "Edit Quote"}
            </h2>{" "}
            <textarea
              placeholder="Write your quote here..."
              value={currentEntry.text}
              onChange={handleTextChange}
              className="w-full h-64 p-2 border border-gray-300 rounded-md resize-none"
            ></textarea>
            <input
              type="text"
              placeholder="Source"
              value={currentEntry.quotee}
              onChange={handleQuoteeChange}
              className="w-full p-2 mb-2 border border-gray-300 rounded-md"
            />
            <div className="flex justify-between mt-4">
              {currentEntry.id && (
                <button
                  onClick={() => deleteEntry(currentEntry.id)}
                  className="flex items-center px-3   transition duration-300"
                >
                  <FaTrash
                    className=" text-gray-600 hover:text-gray-900"
                    size={20}
                  />
                </button>
              )}
              <div />
              <button
                onClick={saveEntry}
                className="px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-300"
              >
                Save Quote
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Archive;

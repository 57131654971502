import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Return from "../../components/Return";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Link } from "react-router-dom";
import ArticlePreview from "../../components/ArticlePre";

const ArticlePage = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  const customBlocks = {
    paragraph: ({ children }) => <p className="mb-4">{children}</p>,
    heading: ({ children, level }) => {
      const Tag = `h${level}`;
      const className = `my-4 text-${level === 1 ? "3xl" : "2xl"}`;
      return <Tag className={className}>{children}</Tag>;
    },
    list: ({ children, format }) => {
      const Tag = format === "ordered" ? "ol" : "ul";
      return <Tag className="list-inside list-disc">{children}</Tag>;
    },
    quote: ({ children }) => (
      <blockquote className="italic border-l-4 pl-4  mb-4 text-gray-800 bg-gray-100">
        {children}
      </blockquote>
    ),
    code: ({ children, plainText }) => (
      <pre>
        <code>{plainText}</code>
      </pre>
    ),
    image: ({ image }) => (
      <img src={image.src} alt={image.alt} className="mx-auto" />
    ),
    link: ({ children, url }) => (
      <Link to={url} className="text-blue-600 hover:underline">
        {children}
      </Link>
    ),
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  const customModifiers = {
    bold: ({ children }) => <strong>{children}</strong>,
    italic: ({ children }) => <em>{children}</em>,
    underline: ({ children }) => <span className="underline">{children}</span>,
    strikethrough: ({ children }) => (
      <span className="line-through">{children}</span>
    ),
    code: ({ children }) => (
      <code className="bg-gray-200 rounded px-1">{children}</code>
    ),
  };
  useEffect(() => {
    setArticle(null);
    fetch(`${apiUrl}/api/articles/${articleId}?populate=*`)
      .then((response) => response.json())
      .then((data) => setArticle(data.data))
      .catch((error) => console.error("Error fetching article:", error));

    fetch(`${apiUrl}/api/articles?populate=*&sort=createdAt:desc`)
      .then((response) => response.json())
      .then((data) => {
        const filteredRelatedArticles = data.data.filter(
          (article) => article.id.toString() !== articleId
        );
        setRelatedArticles(filteredRelatedArticles);
      })
      .catch((error) =>
        console.error("Error fetching related articles:", error)
      );
    window.scrollTo(0, 0);
  }, [articleId]);
  if (!article) return <div className="text-center mt-20">Loading...</div>;

  const content = article.attributes.rich;

  const articleDate = new Date(article.attributes.createdAt);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  })
    .format(articleDate)
    .replace(/\//g, ".");
  return (
    <div className="flex flex-col min-h-screen">
      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 mb-8 mt-28 flex-grow">
        <Return />
        <h1 className="text-4xl font-medium ">{article.attributes.Title}</h1>

        <div className="flex justify-between items-end mb-2">
          <h2 className="text-xl lg:mr-32">{article.attributes.Sub}</h2>
          <span className="text-lg">{formattedDate}</span>
        </div>

        <div className="border-t border-gray-300 "></div>
        <div className="text-center my-4">
          <img
            src={`${article.attributes.Image.data.attributes.formats.medium.url}`}
            className="mx-auto w-full h-auto"
          />
        </div>
        <div className="prose prose-lg mx-auto">
          <BlocksRenderer
            content={content}
            blocks={customBlocks}
            modifiers={customModifiers}
          />
        </div>
      </article>
      <div className="bg-neutral-100 flex-grow">
        <div className="border-t border-gray-300 "></div>

        <div className="mt-8 mx-4 lg:mx-72 mb-14 ">
          <h3 className="text-2xl mb-4">More Articles</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
            {relatedArticles.slice(0, 3).map((article, index) => (
              <ArticlePreview
                articleID={article.id}
                title={article.attributes.Title}
                excerpt={
                  article.attributes.Sub.length > 67
                    ? article.attributes.Sub.slice(0, 67) + "..."
                    : article.attributes.Sub
                }
                imageUrl={`${article.attributes.Image.data.attributes.formats.medium.url}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;

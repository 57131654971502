import React, { useEffect, useState } from "react";
import ArticlePreview from "../../components/ArticlePre";
import { Link } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const Latest = () => {
  const [articles, setArticles] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const customBlocks = {
    paragraph: ({ children }) => <p className="mb-4">{children}</p>,
    heading: ({ children, level }) => {
      const Tag = `h${level}`;
      const className = `my-4 text-${level === 1 ? "3xl" : "2xl"}`;
      return <Tag className={className}>{children}</Tag>;
    },
    list: ({ children, format }) => {
      const Tag = format === "ordered" ? "ol" : "ul";
      return <Tag className="list-inside list-disc">{children}</Tag>;
    },
    quote: ({ children }) => (
      <blockquote className="italic border-l-4 pl-4 text-gray-800 bg-gray-100">
        {children}
      </blockquote>
    ),
    code: ({ children, plainText }) => (
      <pre>
        <code>{plainText}</code>
      </pre>
    ),
    image: ({ image }) => (
      <img src={image.src} alt={image.alt} className="mx-auto" />
    ),
    link: ({ children, url }) => (
      <Link to={url} className="text-blue-600 hover:underline">
        {children}
      </Link>
    ),
  };

  const customModifiers = {
    bold: ({ children }) => <strong>{children}</strong>,
    italic: ({ children }) => <em>{children}</em>,
    underline: ({ children }) => <span className="underline">{children}</span>,
    strikethrough: ({ children }) => (
      <span className="line-through">{children}</span>
    ),
    code: ({ children }) => (
      <code className="bg-gray-200 rounded px-1">{children}</code>
    ),
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch articles
        const articlesResponse = await fetch(
          `${apiUrl}/api/articles?populate=*&sort=createdAt:desc`
        );
        const articlesData = await articlesResponse.json();
        setArticles(articlesData.data.slice(0, 5));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  if (articles.length === 0) {
    return <div>Loading...</div>;
  }

  const latestArticle = articles[0];

  return (
    <div className="mt-10">
      <h2 className="text-2xl mb-4">Latest Articles</h2>
      <div className="flex flex-col lg:flex-row mb-8 block border  overflow-hidden shadow-lg bg-white">
        <div className="lg:w-1/2">
          <img
            src={`${latestArticle.attributes.Image.data.attributes.formats.medium.url}`}
            alt={latestArticle.attributes.Title}
            className="w-full h-auto"
          />
        </div>
        <div className="lg:w-1/2 p-4 flex flex-col justify-center">
          <h3 className="text-2xl">{latestArticle.attributes.Title}</h3>
          <p>{latestArticle.attributes.Sub}</p>
          <div className="border-t border-gray-300 mb-2"></div>

          <BlocksRenderer
            content={latestArticle.attributes.rich.slice(0, 2)}
            blocks={customBlocks}
            modifiers={customModifiers}
          />
          <Link
            to={`/articles/${latestArticle.id}`}
            className="text-center px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-200"
          >
            Continue Reading
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {articles.slice(1, 5).map((article, index) => (
          <ArticlePreview
            key={index}
            articleID={article.id}
            title={article.attributes.Title}
            excerpt={
              article.attributes.Sub.length > 67
                ? article.attributes.Sub.slice(0, 67) + "..."
                : article.attributes.Sub
            }
            imageUrl={`${article.attributes.Image.data.attributes.formats.medium.url}`}
          />
        ))}
      </div>
      <div className="text-right mt-8">
        <Link
          to="/articles"
          className="px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-200"
        >
          See More
        </Link>
      </div>
    </div>
  );
};
export default Latest;

import React, { useState, useEffect } from "react";
import TaskItem from "./TaskItem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const TodoList = ({ category }) => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt") || "");
  const cat = category;
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && newTask.trim() !== "") {
      handleAddTask();
    }
  };
  const handleAddTask = async () => {
    // Removed the e.key check as this function is now directly responsible for adding tasks
    if (newTask.trim() !== "") {
      try {
        const response = await fetch(`${apiUrl}/api/tasks`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            data: {
              text: newTask,
              category: cat,
              completed: false,
            },
          }),
        });
        if (response.ok) {
          const taskData = await response.json();
          setTasks([
            ...tasks,
            {
              id: taskData.data.id, // Assuming Strapi returns the id in taskData.data
              text: taskData.data.attributes.text, // Make sure to adjust according to the actual response structure
              completed: taskData.data.attributes.completed,
            },
          ]);
          setNewTask(""); // Clear input field after adding
        }
      } catch (error) {
        console.error("Failed to add task", error);
      }
    }
  };

  const toggleComplete = async (taskId) => {
    const taskToUpdate = tasks.find((task) => task.id === taskId);
    try {
      const response = await fetch(`${apiUrl}/api/tasks/${taskId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            completed: !taskToUpdate.completed,
          },
        }),
      });
      if (response.ok) {
        setTasks(
          tasks.map((task) =>
            task.id === taskId ? { ...task, completed: !task.completed } : task
          )
        );
      }
    } catch (error) {
      console.error("Error updating task", error);
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      const response = await fetch(
        `${apiUrl}/api/tasks?filters[category][$eq]=${cat}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      const data = await response.json();
      setTasks(
        data.data.map((task) => ({
          id: task.id,
          text: task.attributes.text,
          completed: task.attributes.completed,
        }))
      );
    };

    fetchTasks();
  }, []);

  const deleteTask = async (taskId) => {
    try {
      const response = await fetch(`${apiUrl}/api/tasks/${taskId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (response.ok) {
        setTasks(tasks.filter((task) => task.id !== taskId));
      }
    } catch (error) {
      console.error("Error deleting task", error);
    }
  };

  const moveTask = (dragIndex, hoverIndex) => {
    const dragTask = tasks[dragIndex];
    const newTasks = [...tasks];
    newTasks.splice(dragIndex, 1);
    newTasks.splice(hoverIndex, 0, dragTask);

    setTasks(newTasks);
    // Optionally update the backend or state management system here
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full">
        <ul className="space-y-2">
          {tasks.map((task, index) => (
            <TaskItem
              key={task.id}
              id={task.id}
              index={index}
              text={task.text}
              completed={task.completed}
              onToggleComplete={() => toggleComplete(task.id)}
              onDelete={() => deleteTask(task.id)}
              moveTask={moveTask} // Pass the moveTask function here
            />
          ))}
          <li className="flex items-center space-x-2 ">
            <div className="flex items-center gap-2 w-full px-2 ">
              <label
                htmlFor={`checkbox-1`}
                className="flex-shrink-0 w-6 h-6 rounded-full border-2 border-gray-400 flex items-center justify-center cursor-pointer peer-checked:border-black peer-checked:bg-black"
              ></label>
              <input type="checkbox" className="hidden" />
              <input
                type="text"
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Add a new item..."
                className="flex-1  border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
              />
            </div>
          </li>
        </ul>
      </div>
    </DndProvider>
  );
};

export default TodoList;

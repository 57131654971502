import React, { useEffect, useState } from "react";

const Questions = () => {
  const [questions, setQuestions] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionsResponse = await fetch(
          `${apiUrl}/api/questions?populate=*`
        );
        const questionsData = await questionsResponse.json();
        setQuestions(questionsData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container mx-auto px-4 mt-20">
      <h2 className="text-2xl mb-4">Questions</h2>
      <div className="flex flex-wrap justify-center gap-8">
        {questions.map((question, index) => (
          <div key={index} className="p-4 shadow-lg rounded-lg">
            <h3 className="text-lg">{question.attributes.Text}</h3>
          </div>
        ))}
      </div>
      <h2 className="text-center mt-12">More coming soon...</h2>
    </div>
  );
};

export default Questions;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Login from "../../components/Login";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import { FaUserCircle } from "react-icons/fa";
import NewsletterModal from "../../components/NewsletterModal";

const NavPage = () => {
  const options = [
    {
      icon: "fas fa-list",
      text: "Coming Soon...",
      link: "/daily-evaluation",
      disabled: true,
    },
    {
      icon: "fas fa-question",
      text: "Answer Questions",
      link: "/answer-questions",
      disabled: false,
    },
    {
      icon: "fas fa-book",
      text: "Journal Library",
      link: "/journal-library",
      disabled: false,
    },
    {
      icon: "fas fa-archive",
      text: "Quote Archive",
      link: "/quote-archive",
      disabled: false,
    },
  ];
  const [showMenu, setShowMenu] = useState(false);

  const user = localStorage.getItem("userId");
  const [jwt, setJwt] = useState(localStorage.getItem("jwt") || "");
  const [showLoginModal, setShowLoginModal] = useState(!jwt);
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [quote, setQuote] = useState({
    text: "Upload quotes to get a personalized quote of the day from your Archive!",
    quotee: "Intentional Questions",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showNewsletterModal, setshowNewsletterModal] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setshowNewsletterModal(true);
  //   }, 60000);

  //   return () => clearTimeout(timer);
  // }, []);

  const handleChangePassword = (oldPassword, newPassword) => {
    fetch(`${apiUrl}/api/auth/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        currentPassword: oldPassword,
        password: newPassword,
        passwordConfirmation: newPassword,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Password change failed");
        }
        return response.json();
      })
      .then((data) => {
        alert("Password changed successfully");
        setShowChangePasswordModal(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to change password");
      });
  };
  useEffect(() => {
    const handleStorageChange = () => {
      setJwt(localStorage.getItem("jwt") || "");
    };
    const fetchQuotes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/user-quotes`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const quotesData = await response.json();
        const dayOfYear = getDayOfYear();
        const quoteIndex = dayOfYear % quotesData.data.length;
        setQuote(quotesData.data[quoteIndex].attributes);
      } catch (error) {
        console.error("Failed to fetch entries", error);
      }
    };
    fetchQuotes();
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleLoginSuccess = (token) => {
    localStorage.setItem("jwt", token);
    setJwt(token);
    setShowLoginModal(false); // Close modal on successful login
  };

  const handleLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to logout?");
    if (isConfirmed) {
      localStorage.clear();
      navigate("/");
    }
  };
  const getDayOfYear = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
  };
  return (
    <>
      <NewsletterModal
        onClose={() => setshowNewsletterModal(false)}
        isVisible={showNewsletterModal}
      />
      <div className="relative">
        <span className="absolute right-16 top-16 text-xl py-1 px-2">
          {user}
        </span>
        <button
          className="p-2 rounded-full hover:bg-gray-100 absolute right-4 top-14"
          onClick={() => setShowMenu(!showMenu)}
        >
          <FaUserCircle className="text-4xl" />
        </button>
        {showMenu && (
          <div className="absolute top-24 right-4 mt-4 w-34 bg-white rounded-md shadow-lg py-1 z-50">
            <button
              onClick={() => setShowChangePasswordModal(true)}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Change Password
            </button>
            <button
              onClick={handleLogout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-center h-[80vh]">
        <div className="text-center mt-20">
          <h2 className="text-5xl semi-bold mb-4">Intentional Journal</h2>
        </div>

        <div className="flex justify-center items-center w-full mt-10">
          <div className="grid grid-cols-2 gap-4 sm:gap-8 sm:grid-cols-4 px-4 sm:p-0">
            {options.map((option, index) => (
              <Link
                to={!option.disabled ? option.link : "#"}
                key={index}
                className={`flex flex-col items-center p-8 sm:p-20 border rounded-lg transition-shadow ${
                  option.disabled
                    ? "hover:shadow-md bg-gray-300 text-gray-500 relative"
                    : "hover:shadow-md"
                }`}
              >
                <i className={`${option.icon} text-4xl mb-2`}></i>
                <span className="text-center">{option.text}</span>
              </Link>
            ))}
          </div>
        </div>

        {showLoginModal && <Login onLogin={handleLoginSuccess} />}
        {showChangePasswordModal && (
          <ChangePasswordModal
            onSubmit={handleChangePassword}
            onClose={() => setShowChangePasswordModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default NavPage;

import React, { useState, useEffect } from "react";
import Return from "../../components/Return"
import { FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Journal = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState({
    id: null,
    title: "",
    content: "",
  });
  const [modalState, setModalState] = useState(null); // null, "new", or entry object
  const jwt = localStorage.getItem("jwt");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/journals?populate=*`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const data = await response.json();
        setEntries(data.data);
      } catch (error) {
        console.error("Failed to fetch entries", error);
      }
    };

    if (jwt) {
      fetchEntries();
    }
  }, [jwt]);

  const handleTitleChange = (e) =>
    setCurrentEntry({ ...currentEntry, title: e.target.value });
  const handleContentChange = (e) =>
    setCurrentEntry({ ...currentEntry, content: e.target.value });

  const openModal = (entry = null) => {
    if (entry) {
      setCurrentEntry({
        id: entry.id,
        title: entry.attributes.title,
        content: entry.attributes.content,
      });
      setModalState(entry);
    } else {
      setCurrentEntry({ id: null, title: "", content: "" });
      setModalState("new");
    }
  };
  const closeModal = () => {
    setModalState(null);
    setCurrentEntry({ id: null, title: "", content: "" });
  };
  const saveEntry = async () => {
    const url = currentEntry.id
      ? `${apiUrl}/api/journals/${currentEntry.id}`
      : `${apiUrl}/api/journals`;

    const method = currentEntry.id ? "PUT" : "POST";
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            title: currentEntry.title,
            content: currentEntry.content,
          },
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (currentEntry.id) {
          setEntries(
            entries.map((entry) =>
              entry.id === currentEntry.id ? data.data : entry
            )
          );
        } else {
          setEntries([...entries, data.data]);
        }
        setCurrentEntry({ title: "", content: "" });
        setModalState(false);
        toast.success("Entry Saved");
      } else {
        toast.error("Entry Failed to Save");
      }
    } catch (error) {
      console.error("Failed to save entry", error);
      toast.error("Failed to save entry");
    }
  };

  const deleteEntry = async (entryId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this entry?"
    );

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}/api/journals/${entryId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (response.ok) {
          setEntries(entries.filter((entry) => entry.id !== entryId));
          closeModal();
        } else {
          console.error("Failed to delete entry");
        }
      } catch (error) {
        console.error("Failed to delete entry", error);
      }
    }
  };

  return (
    <div className="px-12 mt-14">
      <Return />
      <div className="flex justify-between items-center mb-10 mt-32 px-2">
        <h2 className="text-2xl">Journal Library</h2>
        <button
          onClick={() => openModal()}
          className="px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-300"
        >
          <i className="fas fa-edit"></i>
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 ">
      {entries.map((entry, index) => (
        <div
          key={entry.id}
          onClick={() => openModal(entry)}
          className="block border rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in-out bg-white cursor-pointer"
        >
          <div className="p-4">
            <h2 className="text-2xl">{entry.attributes.title}</h2>
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html:
                  entry.attributes.content.length > 70
                    ? entry.attributes.content.slice(0, 70) + "..."
                    : entry.attributes.content,
              }}
            />
          </div>
        </div>
        ))}
      </div>
      {modalState && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 flex justify-center items-center">
          <div className="relative bg-white p-8 rounded-lg w-11/12 sm:w-3/4 md:w-1/2">
            {/* Close button */}
            <button
              onClick={() => setModalState(false)}
              className="absolute top-0 right-0 mt-2 mr-2 p-2 bg-transparent text-black hover:text-gray-700 font-semibold"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <input
              type="text"
              placeholder="Title"
              value={currentEntry.title}
              onChange={handleTitleChange}
              className="w-full p-2 mt-4 mb-2 border border-gray-300 rounded-md"
            />
            <ReactQuill
              theme="snow"
              value={currentEntry.content}
              onChange={(content) =>
                setCurrentEntry({ ...currentEntry, content })
              }
              style={{ height: "50vh", marginBottom: "60px" }}
            />
            <div className="flex justify-between mt-4">
              {currentEntry.id && (
                <button
                  onClick={() => deleteEntry(currentEntry.id)}
                  className="flex items-center px-3   transition duration-300"
                >
                  <FaTrash
                    className=" text-gray-600 hover:text-gray-900"
                    size={20}
                  />
                </button>
              )}
              <div />
              <button
                onClick={saveEntry}
                className="px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-300"
              >
                Save Entry
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Journal;

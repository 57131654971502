import React, { useState, useEffect } from "react";
import Return from "../../components/Return";

const AnswerQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState({
    id: null,
    answer: "",
    question: "",
  });
  const [answersMap, setAnswersMap] = useState({});
  const jwt = localStorage.getItem("jwt");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch answers
    const fetchAnswers = async () => {
      try {
        const response = await fetch(`${apiUrl}/answers`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const answerData = await response.json();
        const tempAnswersMap = {};
        // Assuming that answerData.data is an array of answer objects
        answerData.data.forEach((item) => {
          tempAnswersMap[item.attributes.question] = {
            id: item.id,
            answer: item.attributes.answer,
            question: item.attributes.question,
          };
        });
        setAnswersMap(tempAnswersMap);
      } catch (error) {
        console.error("Failed to fetch answers", error);
      }
    };

    // Fetch questions
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/questions`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const questionData = await response.json();
        setQuestions(questionData.data);
      } catch (error) {
        console.error("Failed to fetch questions", error);
      }
    };

    if (jwt) {
      fetchQuestions();
      fetchAnswers();
    }
  }, [jwt]);

  const handleAnswerSubmit = async () => {
    if (!selectedQuestion || !answer.answer.trim()) return;

    const existingAnswer = answersMap[selectedQuestion.attributes.Text];
    const url = existingAnswer
      ? `${apiUrl}/api/answers/${existingAnswer.id}`
      : `${apiUrl}/api/answers`;

    const method = existingAnswer ? "PUT" : "POST";

    const bodyData = {
      data: {
        answer: answer.answer,
        question: selectedQuestion.attributes.Text,
      },
    };

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();
      if (response.ok) {
        setAnswersMap({
          ...answersMap,
          [selectedQuestion.attributes.Text]: {
            id: data.data.id,
            answer: data.data.attributes.answer,
            question: data.data.attributes.question,
          },
        });

        alert("Answer saved successfully.");
      } else {
        console.error("Failed to submit answer", data);
      }
    } catch (error) {
      console.error("Failed to save answer", error);
    }
  };

  const handleSelectQuestion = (question) => {
    setSelectedQuestion(question);
    const answerObj = answersMap[question.attributes.Text];
    setAnswer({
      id: answerObj ? answerObj.id : null,
      answer: answerObj ? answerObj.answer : "",
      question: question.attributes.Text,
    });
  };

  const handleAnswerChange = (e) => {
    setAnswer({
      ...answer,
      answer: e.target.value,
    });
  };
  const answeredCount = questions.reduce((count, question) => {
    if (answersMap[question.attributes.Text]) {
      count++;
    }
    return count;
  }, 0);

  // Calculate the progress percentage
  const progressPercentage = (answeredCount / questions.length) * 100;

  return (
    <div className="px-12 mt-14 max-h-[80vh]">
      <Return />

      <h2 className="text-2xl mb-4 mt-32">Answer Questions</h2>
      <div className="mb-2">
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-gray-600 h-2.5 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/3 overflow-auto max-h-[78vh]">
          {questions.map((question) => (
            <div
              key={question.id}
              onClick={() => handleSelectQuestion(question)}
              className={`p-4 shadow-lg rounded-lg cursor-pointer ${
                question.answered ? "bg-gray-200" : "hover:bg-gray-100"
              }`}
            >
              {question.attributes.Text}
            </div>
          ))}
        </div>
        <div className="flex-1 ml-4">
          {selectedQuestion ? (
            <>
              <div className="mb-4 border border-gray-300 rounded-md p-2 bg-gray-50">
                {selectedQuestion.attributes.Text}
              </div>
              <textarea
                value={answer.answer}
                onChange={handleAnswerChange}
                className="w-full h-64 p-2 border border-gray-300 rounded-md resize-none"
                placeholder="Answer here..."
              ></textarea>
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleAnswerSubmit}
                  className="px-3 py-2 rounded border border-black bg-white text-black hover:bg-gray-100 transition duration-300"
                >
                  Submit Answer
                </button>
              </div>
            </>
          ) : (
            <div className="text-center mt-60">
              <p className="text-2xl text-gray-700">Select a question</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnswerQuestions;

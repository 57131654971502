import React from "react";
import ArticlePreview from "../../components/ArticlePre";
import { useEffect, useState } from "react";
import NewsletterModal from "../../components/NewsletterModal";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Articles");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showNewsletterModal, setshowNewsletterModal] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setshowNewsletterModal(true);
  //   }, 60000);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    fetch(`${apiUrl}/api/articles?populate=*&sort=createdAt:desc`)
      .then((response) => response.json())
      .then((data) => setArticles(data.data))
      .catch((error) => console.error("Error fetching articles:", error));
  }, []);

  const filteredArticles = articles.filter(
    (article) =>
      selectedCategory === "All Articles" ||
      article.attributes.Category === selectedCategory
  );

  return (
    <div className="container mx-auto px-4 mt-20 mb-20">
      <NewsletterModal
        onClose={() => setshowNewsletterModal(false)}
        isVisible={showNewsletterModal}
      />
      <div className="flex items-center mb-4 gap-4">
        <h2 className="text-2xl">Articles</h2>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="p-2 border"
        >
          <option value="All Articles">All</option>
          <option value="Book Notes">Book Notes</option>
          {/* <option value="Minimalism">Minimalism</option>
          <option value="Questions">Questions</option> */}
        </select>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredArticles.map((article, index) => (
          <ArticlePreview
            articleID={article.id}
            title={article.attributes.Title}
            excerpt={
              article.attributes.Sub.length > 67
                ? article.attributes.Sub.slice(0, 67) + "..."
                : article.attributes.Sub
            }
            imageUrl={`${article.attributes.Image.data.attributes.formats.medium.url}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Articles;

import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

const DailyThoughts = () => {
  const [quoteOfTheDay, setQuote] = useState("");
  const [questionOfTheDay, setQuestion] = useState("");
  const [suggestedQuestion, setSuggestedQuestion] = useState("");
  const [suggestedQuote, setSuggestedQuote] = useState("");

  const jwt = localStorage.getItem("jwt");
  const user = localStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmitQuestion = async (event) => {
    event.preventDefault();
    if (!jwt) {
      toast.error("You must be logged in to submit a question");
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/suggested-questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },

        body: JSON.stringify({
          data: {
            question: suggestedQuestion,
            user: user,
          },
        }),
      });
      if (response.ok) {
        toast.success("Question Submitted");
        setSuggestedQuestion(""); // Clear the input after successful submission
      } else {
        toast.error("Failed to submit question. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  const handleSubmitQuote = async (event) => {
    event.preventDefault();
    if (!jwt) {
      toast.error("You must be logged in to submit a quote");
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/suggested-quotes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          data: {
            quote: suggestedQuote,
            user: user,
          },
        }),
      });
      if (response.ok) {
        toast.success("Quote Submitted");
        setSuggestedQuote(""); // Clear the input after successful submission
      } else {
        toast.error("Submission Failed");
      }
    } catch (error) {
      console.error("Error submitting quote:", error);
    }
  };

  const getDayOfYear = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);
    return day;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch quotes
        const quotesResponse = await fetch(`${apiUrl}/api/quotes?populate=*`);
        const quotesData = await quotesResponse.json();
        const dayOfYear = getDayOfYear();
        const quoteIndex = dayOfYear % quotesData.data.length;
        setQuote(quotesData.data[quoteIndex].attributes);

        // Fetch questions
        const questionsResponse = await fetch(
          `${apiUrl}/api/questions?populate=*`
        );
        const questionsData = await questionsResponse.json();
        console.log(questionsData);
        const questionIndex = dayOfYear % questionsData.data.length;
        setQuestion(questionsData.data[questionIndex].attributes.Text);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="flex justify-center gap-8 mt-14">
        <div className="w-1/2 p-4 text-center block border  overflow-hidden shadow-lg bg-white">
          <h3 className="font-medium text-xl mb-4">Question of the Day</h3>
          <blockquote className="italic mb-4 text-gray-800 text-xl">
            {questionOfTheDay}
          </blockquote>
        </div>
        <div className="w-1/2 p-4 text-center block border  overflow-hidden shadow-lg bg-white">
          <h3 className="font-medium text-xl mb-4 ">Quote of the Day</h3>
          <blockquote className="italic mb-4 text-gray-800 text-xl">
            "{quoteOfTheDay.text}"
          </blockquote>
          <div className="text-md mt-4 flex justify-end">
            <cite>{"- " + quoteOfTheDay.quotee}</cite>
          </div>
        </div>
        {/* <div className="flex-1 w-full md:w-1/2 p-4 shadow-lg rounded-lg">
          <h3 className="font-bold text-lg mb-4">Submit For Tomorrow</h3>
          <form onSubmit={handleSubmitQuestion}>
            <div className="flex mb-4">
              <input
                type="text"
                placeholder="Suggest a question for tomorrow"
                value={suggestedQuestion}
                onChange={(e) => setSuggestedQuestion(e.target.value)}
                className="p-2 border border-r-0 border-gray-300 rounded-l-md flex-1"
              />
              <button
                type="submit"
                className="bg-gray-200 text-black border border-gray-300 rounded-r-md px-4 hover:bg-gray-300 transition duration-200"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </button>
            </div>
          </form>
          <form onSubmit={handleSubmitQuote}>
            <div className="flex">
              <input
                type="text"
                placeholder="Suggest a quote for tomorrow"
                value={suggestedQuote}
                onChange={(e) => setSuggestedQuote(e.target.value)}
                className="p-2 border border-r-0 border-gray-300 rounded-l-md flex-1"
              />
              <button
                type="submit"
                className="bg-gray-200 text-black border border-gray-300 rounded-r-md px-4 hover:bg-gray-300 transition duration-200"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default DailyThoughts;

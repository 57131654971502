import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const Return = () => {
  const navigate = useNavigate();

  return (
    <div>
      <button
        onClick={() => navigate(-1)} // Navigates back to the previous page
        className="absolute top-16 lg:left-8  md:left-8 flex items-center justify-center bg-white text-black border-2 border-black rounded-full h-12 w-12 cursor-pointer hover:bg-gray-100  transition duration-200"
        aria-label="Return"
      >
        <FaArrowLeft size={20} />
      </button>
    </div>
  );
};

export default Return;

import React, { useState } from "react";
import HabitTracker from "./HabitTracker";
import Return from "../../components/Return";
import TodoList from "./TodoList";

const Daily = () => {
  return (
    <div className="mt-24">
      <Return />
      <div className="flex h-[82vh] justify-center gap-8">
        <div className="w-1/2 p-4 flex flex-col">
          <div className="h-full mb-4 shadow-lg rounded-lg p-4 rounded-md ">
            <h2 className="text-2xl mb-4">Daily Evaluation</h2>
            <TodoList category={"1"} />
          </div>
        </div>
        <div className="w-1/3 p-4 flex flex-col h-[80vh]">
          <div className="h-1/2 mb-4 shadow-lg rounded-lg p-4 rounded-md overflow-auto">
            <h2 className="text-2xl mb-4">What's most important to you?</h2>
            <TodoList category={"2"} />
          </div>
          <div className="h-1/2 shadow-lg rounded-lg p-4 rounded-md overflow-auto">
            <h2 className="text-2xl mb-4">Who do you want to be?</h2>
            <TodoList category={"3"} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Daily;

import React from "react";
import { useDrag, useDrop } from "react-dnd";

const TaskItem = ({
  id,
  text,
  completed,
  onToggleComplete,
  onDelete,
  moveTask,
  index,
}) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "task",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, dropRef] = useDrop(() => ({
    accept: "task",
    hover(item, monitor) {
      if (item.index === index) {
        return;
      }
      moveTask(item.index, index);
      item.index = index;
    },
  }));

  return (
    <div
      ref={dragRef}
      className={`flex items-center space-x-2 ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      <div className="flex items-center space-x-2">
        <input
          id={`checkbox-${id}`}
          type="checkbox"
          className="hidden peer"
          checked={completed}
          onChange={onToggleComplete}
        />
        <label
          htmlFor={`checkbox-${id}`}
          className="w-6 h-6 rounded-full border-2 border-gray-400 flex items-center justify-center cursor-pointer peer-checked:border-black peer-checked:bg-black"
        >
          {completed && (
            <svg
              className="w-4 h-4 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </label>
        <span className={`flex-1 ${completed ? "line-through" : ""}`}>
          {text}
        </span>
        <button
          onClick={onDelete}
          className="text-gray-400 hover:text-gray-700"
        >
          {/* Button content, like an SVG icon */}
        </button>
      </div>{" "}
    </div>
  );
};

export default TaskItem;

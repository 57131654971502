import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Articles from "./pages/articles/Articles";
import Navbar from "./components/Navbar";
import ArticlePage from "./pages/articles/ArticlePage";
import Questions from "./pages/questions/Questions";
import NavPage from "./pages/journal/NavPage";
import Journal from "./pages/journal/Journal";
import Archive from "./pages/journal/Archive";
import Daily from "./pages/journal/Daily";
import AnswerQuestions from "./pages/journal/AnswerQuestions";
import "./index.css";
import { Analytics } from "@vercel/analytics/react";
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/intentional-journal" element={<NavPage />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/journal-library" element={<Journal />} />
          <Route path="/daily-evaluation" element={<Daily />} />
          <Route path="/quote-archive" element={<Archive />} />
          <Route path="/answer-questions" element={<AnswerQuestions />} />
          <Route path="/articles/:articleId" element={<ArticlePage />} />
        </Routes>
        <Analytics />
        <Toaster
          toastOptions={{
            style: {
              marginTop: "40px",
            },
            iconTheme: {
              primary: "#000",
              secondary: "#FFF",
            },
          }}
        />
      </div>
    </Router>
  );
}

export default App;

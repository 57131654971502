const HomeFooter = () => {
  return (
    <footer className="w-full bg-white">
      <div className="w-full h-auto overflow-hidden opacity-95">
        <img
          src="/mountain-footer.png"
          alt="Mountain Footer"
          className="min-w-full h-full object-cover"
        />
      </div>
    </footer>
  );
}

export default HomeFooter;
import React from "react";

function NewsletterModal({ onClose, isVisible }) {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-2 rounded-lg shadow-lg w-auto relative">
        <button
          onClick={() => onClose(false)}
          className="absolute top-0 right-0 mt-4 mr-4 text-gray-700 hover:text-gray-900"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <iframe
          src="https://intentionalquestions.substack.com/embed"
          width="430"
          height="300"
          frameBorder="0"
          title="Substack Signup"
        ></iframe>
      </div>
    </div>
  );
}

export default NewsletterModal;
